@import url('https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inconsolata/v21/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inconsolata/v21/QlddNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLyya15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyC0ITw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptug8zYS_SKggPNyC0ITw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes paneBorder {
  0% {
    border-color:  rgba(33, 186, 69, 0);
  }
  100% {
    border-color:  rgba(33, 186, 69, 1);
  }
}

html {
  font-size: 0.8em;
}

body {
  background-color: hsl(207, 100%, 98%) !important;
  font-size: 1em;
  line-height: 1em;
  overflow: auto;
  font-family: Roboto;
}

#notices .md-block-unordered-list-item li, #announcements .md-block-unordered-list-item li {
  margin-left: 15px;
  list-style: initial;
}

.hide-label label {
  display: none !important;
}

.pointer > .ui.label {
  background: transparent;
}

i.icon.hover:hover {
  cursor: pointer
}

i.icon.favorite.star.outline:hover {
  font-family: icons;
  cursor: pointer;
}

i.icon.favorite.star:hover {
  font-family: outline-icons;
  cursor: pointer;
}

i {
  outline: none !important;
}

.lock:hover i {
  color: #2185d0 !important;
  cursor: pointer;
}

.label.zero i {
  margin: 0 !important;
}

.search .result .label:hover {
  background-color: #aaa !important;
}

.error .ui.dropdown.fork {
  background: white !important;
}

.error .ui.selection.dropdown > i {
  float: right;
}

.ui.select .dropdown {
  width: 100%;
}

.ui.dropdown.fork {
  float: right;
}

.ui.dropdown.fork .dropdown.icon {
  margin: 0;
}

.ui.menu .item.search {
  padding: 8px;
}

.ui.menu .item.search input {
  padding: 6px;
}

.nav.menu .item {
  padding-right: 0 !important;
}

.nav.menu .label {
  visibility: hidden;
}

.nav.menu .item:hover .label {
  visibility: visible;
}

.nav.menu .label:hover {
  background-color: #bbb !important;
}

.nav.menu .label.blue:hover {
  background-color: #1678c2 !important;
}

.nav.menu .label.red:hover {
  background-color: #d01919 !important;
}

#footer {
  position: absolute;
  bottom: 2px;
  left: 5px;
  right: 5px;
  text-align: center;
  color: #C4C8CC;
  font-size: 0.9em;
}

#pane-arrow:hover + #pane-drawer, #pane-drawer:hover {
  height: 48px !important;
}

/* .react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
} */

/*home page styles*/

.pointer:hover {
  cursor: pointer;
}

.notification {
  animation: blinker 2s linear infinite;
}

.inmate-pending-attendance {
  border: 2px solid rgb(219, 40, 40);
  border-radius: 20px;
  position: absolute;
  width: 24px;
  height: 23px;
  top: 2px;
  left: 11px;
  animation: blinker 2s linear infinite;
}

.class-pending-attendance {
  border: 2px solid rgb(219, 40, 40);
  border-radius: 20px;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 5px;
  left: 63px;
  animation: blinker 2s linear infinite;
}

.mode {
  display: inline-block !important;
}

.mode:hover {
  opacity: 1 !important;
  cursor:  pointer;
}

.md-side-toolbar {
  left: -15px;
}

#chart canvas {
  margin: auto;
  width: 100%;
}

.ui.page.modals {
  overflow-y: auto;
}

.ui.selection.dropdown {
  min-width: 4em !important;
}

.Toastify__toast-body {
  line-height: 1.1em;
}

.full-search, .full-search > .ui.input {
  width: 100%
}

.noicon .ui.input input {
  max-width: 125px;
  padding-right: 0;
}

.noicon .ui.input i {
  display: none;
}

.ui.button.icon.icon-only {
  padding-left: 14px !important;
}

.ui.secondary.menu .item.active {
  background: rgba(0,0,0,.05);
  color: rgba(0,0,0,.95);
}

.ui.secondary.menu.blue:hover .item {
  background: rgb(33, 133, 208);
  color: white;
}

.ui.compact.table td {
  padding: .4em .7em;
}

.blue > .ui.menu {
  background-color: #2185D0;
  /*flex-wrap: wrap;*/
}

.blue > .ui.menu .item {
  color: white;
}

.blue > .ui.menu .item.active {
  color: white;
}

.blue > .ui.menu .item:hover {
  color: white;
}

.ui.green.menu.inverted {
  background: rgb(32, 180, 67) !important;
}

.ui.secondary.menu.green:hover .item {
  background: rgb(32, 180, 67);
  color: white;
}

.ui.compact.table td {
  padding: .4em .7em;
}

.green > .ui.menu {
  background-color: rgb(32, 180, 67);
}

.green > .ui.menu .item {
  color: white;
}

.green > .ui.menu .item.active {
  color: white;
}

.green > .ui.menu .item:hover {
  color: white;
}

.footer {
  margin-top:  10px;
  padding-bottom: 10px;
}

.footer .center {
  text-align: center;
}

.footer .left {
  float: left;
  margin-left: 10px;
}

.footer .right {
  float: right;
  margin-right: 10px;
}

.field.small input {
  padding: 6px !important
}

.react-datepicker-popper {
  z-index: 9 !important
}

.md-RichEditor-root {
  padding: 1px 16px !important
}

.masthead {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  background-image: linear-gradient(
          135deg,
          rgb(24, 42, 115) 0%,
          rgb(33, 138, 174) 69%,
          rgb(32, 167, 172) 89%
  ) !important;
  min-height: 100vh;
  max-height: 100%;
  background-size: cover;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2.ui.header {
  font-size: 3em;
  font-weight: normal;
}

.masthead h3 {
  font-size: 1.7em;
  font-weight: normal;
}

/*end home page styles*/

.button-nav:hover {
  width: 100px
}

.inmate-image:hover {
  cursor: pointer;
}

.icon.inmates, .icon.tabe, .icon.template, .icon.ged, .icon.hiset, .icon.riasec, .icon.cert, .icon.class {
  transition: all 0.4s ease;
  width: 100%;
  max-width: 36px;
}

.icon.inmates:hover, .icon.tabe:hover, .icon.template:hover, .icon.ged:hover, .icon.hiset:hover, .icon.riasec:hover, .icon.cert:hover, .icon.class:hover, .icon.active {
  text-align: left;
}

.icon.inmates:hover {
  max-width: 80px;
}

.icon.tabe:hover {
  max-width: 65px;
}

.icon.template:hover, .icon.template.active {
  max-width: 98px;
}

.icon.ged:hover {
  max-width: 58px;
}

.icon.hiset:hover {
  max-width: 68px;
}

.icon.riasec:hover {
  max-width: 78px;
}

.icon.cert:hover, .icon.cert.active {
  max-width: 104px;
}

.icon.class:hover, .icon.class.active {
  max-width: 82px;
}

.icon.inmates:hover .icon-i:before {
  content: 'Inmates';
  padding-left: 5px;
}

.icon.tabe:hover .icon-t:before {
  content: 'TABE';
  padding-left: 5px;
}

.icon.template:hover .icon-t:before, .icon.template.active .icon-t:before {
  content: 'Templates';
  padding-left: 5px;
}

.icon.ged:hover .icon-g:before {
  content: 'GED';
  padding-left: 5px;
}

.icon.hiset:hover .icon-h:before {
  content: 'HiSET';
  padding-left: 5px;
}

.icon.riasec:hover .icon-r:before {
  content: 'RIASEC';
  padding-left: 5px;
}

.icon.cert:hover .icon-c:before, .icon.cert.active .icon-c:before {
  content: 'Certificates';
  padding-left: 5px;
}

.icon.class:hover .icon-c:before, .icon.class.active .icon-c:before {
  content: 'Classes';
  padding-left: 5px;
}

.icon-i:before {
  font-family: 'Roboto';
  font-weight: 700;
  content: 'I';
  transition: content 0.4s ease 0.4s;
}

.icon-g:before {
  font-family: 'Roboto';
  font-weight: 700;
  content: 'G';
}

.icon-t:before {
  font-family: 'Roboto';
  font-weight: 700;
  content: 'T';
}

.icon-h:before {
  font-family: 'Roboto';
  font-weight: 700;
  content: 'H';
}

.icon-r:before {
  font-family: 'Roboto';
  font-weight: 700;
  content: 'R';
}

.icon-c:before {
  font-family: 'Roboto';
  font-weight: 700;
  content: 'C';
}

.link {
  color: #4183c4;
}

.link:hover {
  cursor: pointer;
}

tr.hover th:hover {
  cursor: pointer;
}

.popup .label:hover {
  cursor: pointer
}

.close i:hover {
  cursor: pointer
}

.react-datepicker-wrapper {
  width: 100%;
}

.ui.modals.top {
  overflow: auto;
}

.assign-certificate .ui.category.search > .ui.input {
  width: 100%;
}

.create-class .ui.category.search > .ui.input {
  width: 300px;
}

.create-class .ui.category.search .category > .name {
  white-space: inherit;
  width: 25em;
}

.create-class .ui.category.search > .results {
  width: 50em;
}

.ui.search>.results .result {
  position: relative;
}

.ui.search>.results .result .content {
  position: relative;
  z-index: 1;
}

.accordion .list .item:hover {
  color: rgba(0,0,0,.8);
}

.inline-fields > div {
  display: inline-block;
  margin-right: 10px !important;
}

.ui.table.slanted tr:first-child td {
  border-top: 1px solid rgba(34,36,38,.1);
}

th.rotate {
  height: 140px;
  white-space: nowrap;
}

th.rotate > div {
  transform: 
    translate(-2px, 72px)
    rotate(315deg);
  width: 30px;
}

th.rotate > div > span {
  border-top: 1px solid #ccc;
  padding: 8px 15px 8px 30px;
}

#dimmer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0);
  z-index: 1;
  transition: background-color 1s ease-out;
}

#dimmer.hidden {
  width: 0%;
  height: 0%;
}

#dimmer.active {
  background-color: rgba(255,255,255,0.5);
}

#drawer {
  position: fixed;
  top: 50px;
  bottom: 50px;
  left: -1px;
  z-index: 900;
  background-color: white;
  border: thin solid #ddd;
  border-left: 0;
  overflow: hidden;
  overflow-y: auto;
  white-space: nowrap;
  transition: width 1s ease-out;
  border-radius: 0 3px 3px 0;
}

#drawer.surveys {
  top: 79px;
}

#drawer.open {
  width: min(1427px, 100%);
}

#drawer.closed {
  width: 0px
}

#drawer > .content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  right: 20px;
}

.nopad .ui.selection.dropdown .menu > .item {
  padding: 0 !important
}

.career-labels .label .icon:hover {
  cursor: pointer;
}

.full-accord .accordion {
  width: 100% !important;
}

#filters button {
  padding-left: 14px;
  padding-right: 14px;
}

#inmates-table, #classes-table, #templates-table, #certificates-table, #tabe-table, #ged-table, #hiset-table, #riasec-table, #ands-table, #uas-table {
  overflow: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

#inmates-table th, #classes-table th, #templates-table th, #certificates-table th, #tabe-table th, #ged-table th, #hiset-table th, #riasec-table th, #ands-table th, #uas-table th {
  z-index: 1;
}

#inmates-table thead th:hover {
  cursor: pointer;
}

#inmates-table thead th i {
  margin: 0;
}

#staff-table thead th:hover {
  cursor: pointer;
}

.segment.tab {
  padding: 0 !important;
  border: 0 !important;
}

#pane-1 {
  left: 0;
  border-right:thin solid #ddd;
}

#pane-2 {
  right: 0px;
  border-left:thin solid #ddd;
}

.pane {
  position: absolute;
  top: 45px;
  bottom: 18px;
  transition: width 1s ease-out;
  overflow: hidden;
  vertical-align: top;
}

.pane.print {
  bottom: 0;
  overflow: inherit;
  background-color: white;
}

#pane-1.print {
  width: 100% !important;
  top: 0;
  left: 0;
  z-index: 999;
}

#pane-2.print {
  width: 100% !important;
  top: 0;
  right: 0;
  z-index: 999;
}

.pane.print > .content-pane {
  margin: 0;
  border-radius: 0;
  bottom: auto;
}

.pane.print table {
  font-size: 0.9em;
}

.content-pane {
  position:absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  overflow:hidden;
  white-space:nowrap;
  overflow-y: auto;
  transition:opacity 1s ease-in;
  margin: 20px;
  background-color: white;
  border-radius: 20px;
  border: 10px solid rgba(33, 186, 69, 0);
  animation-name: none;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.content-pane.animated {
  animation-name: paneBorder;
}

.content-pane > .content {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 40px;
}

.survey {
  padding: 8px;
  color: white;
  background-color: #DB2828;
}

.survey:hover {
  cursor: pointer;
  text-decoration: underline;
}

.field.grade .menu {
  height: 260px;
}

.field.transparent input, .field.transparent textarea, .field.transparent .ui.selection {
  border: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.field.transparent .dropdown.icon {
  display: none;
}

.field.transparent.compact .search, .field.transparent.compact i {
  padding: 2px;
  min-height: 0px;
}

.field.transparent.compact input {
  padding: 0px !important;
}

#pdfs, #csvs {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

#pdfs .icons, #pdfs .icon, #csvs .icons, #csvs .icon {
  width: 100%;
}

#pdfs .name, #csvs .name {
  margin-top: 10px;
}

.pdf.generating .name, .csv.generating .name {
  color: rgb(118, 118, 118);
}

.pdf.ready:hover, .csv.ready:hover {
  cursor: pointer;
}

/*.field.inverted label {
  font-weight: 400 !important;
}

.field.inverted input, .field.inverted textarea, .field.inverted .ui > .divider.text:not(.default) {
  font-weight: 700 !important;
}

.field.red input, .field.red textarea, .field.red .ui > .divider.text:not(.default) {
  color: rgb(219, 40, 40) !important;
}

.field.blue input, .field.blue textarea, .field.blue .ui > .divider.text:not(.default) {
  color: rgb(22, 120, 194) !important;
}

.field.inverted-radio label {
  font-weight: 700 !important;
}

.field.inverted-radio.red label {
  color: rgb(219, 40, 40) !important;
}

.field.inverted-radio.blue label {
  color: rgb(22, 120, 194) !important;
}*/
